import React, { useState, useEffect } from "react";
import { Form, Row, Col, Table, Spinner } from "react-bootstrap";
// import POSProducts from "../../Assets/POS-Products";
import { connect } from "react-redux";
import { getPOSProducts, purchasePOSProduct } from "../../Actions/allActions";

const POS = ({ all: { posProducts }, getPOSProducts, purchasePOSProduct }) => {
	const [posList, setPosList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [purchaseLoader, setPurchaseLoader] = useState(false);
	const [itemSelected, setItemSelected] = useState(null);
	const [purchaseProduct, setPurchaseProduct] = useState({
		itemid: null,
		itemname: null,
		itemqty: 1,
		userid: JSON.parse(localStorage.getItem("IDS")).usersiloid,
		username: JSON.parse(localStorage.getItem("IDS")).siloname,
	});
	useEffect(() => {
		getPOSProducts(localStorage.getItem("token"));
	}, [getPOSProducts]);
	useEffect(() => {
		if (posProducts) {
			setLoading(false);
			setPosList(posProducts.TableData);
		}
	}, [posProducts, posList]);

	const productSelect = (id) => {
		const selected_product = posList.filter((i) => i.ItemId === id)[0];
		//console.log(selected_product, "selected_product");
		setItemSelected(selected_product);
	};

	const productPurchase = async (e, data) => {
		//console.log(id, "id");
		e.preventDefault();
		setPurchaseLoader(true);
		let purchaseSelectedProduct = {
			itemid: data.ItemId,
			itemname: data.ItemDescription,
			itemqty: purchaseProduct.itemqty,
			userid: purchaseProduct.userid,
			username: purchaseProduct.username,
		};
		const res = await purchasePOSProduct(localStorage.getItem("token"), purchaseSelectedProduct);
		if (res) {
			setPurchaseLoader(false);
			setItemSelected(null);
		}
	};
	return (
		<div className="widget">
			<div className="widget-head">POS</div>
			<div className="widget-body">
				<Row>
					<Col lg={9}>
						{loading ? (
							<div className="pos-loader">
								<Spinner animation="border" role="status"></Spinner>
							</div>
						) : (
							""
						)}
						<Row md={4} className="gx-4">
							{posList.length > 0 && !loading
								? posList.map((i, v) => (
										<Col key={v}>
											<div className="pos-card">
												<div className="image-container">
													<img src={`data:image/png;base64, ${i.ItemImage}`} alt="neft product" />
												</div>
												<div className="card-body">
													<h6>{i.ItemDescription}</h6>
												</div>
												<div className="card-footer">
													<button className="btn btn-main btn-sm" onClick={() => productSelect(i.ItemId)}>
														Add to Cart
													</button>
												</div>
											</div>
										</Col>
								  ))
								: ""}
						</Row>
					</Col>
					<Col lg={3}>
						<div className="pos-price">
							<Table responsive borderless className="align-middle">
								<thead className="table-light text-muted">
									<tr>
										<th width="90" scope="col">
											Product
										</th>
										<th scope="col">Product Info</th>
										<th>Quantity</th>
									</tr>
								</thead>
								<tbody>
									{itemSelected ? (
										<tr>
											<td>
												<img src={`data:image/png;base64, ${itemSelected.ItemImage}`} alt="neft product" className="pos-selected-img" />
											</td>
											<td>
												<div className="d-flex align-items-center">
													<h6 className="mb-0">{itemSelected.ItemDescription}</h6>
												</div>
											</td>
											<td>
												<Form.Control
													className="form-control quantity"
													name="itemqty"
													type="number"
													defaultValue="1"
													onChange={(e) =>
														setPurchaseProduct({
															...purchaseProduct,
															[e.target.name]: parseInt(e.target.value),
														})
													}
												/>
											</td>
										</tr>
									) : (
										<tr>
											<td colSpan={3} className="text-center">
												No Items
											</td>
										</tr>
									)}
								</tbody>
							</Table>
							<div>
								<button type="submit" className="btn btn-main w-100" disabled={purchaseLoader || itemSelected == null} onClick={(e) => productPurchase(e, itemSelected)}>
									{purchaseLoader ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Purchase"}
								</button>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	error: state.error,
	all: state.all,
});

export default connect(mapStateToProps, { getPOSProducts, purchasePOSProduct })(POS);
