import React, { Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { generateToast } from "../Actions/allActions";

const Dashboard = ({
	auth: { authResponse, security, requestsCount, assignedTasksCount, internalTasksCount },
	error: { msg, status },
	generateToast,
}) => {
	// let userSiloId = localStorage.getItem("IDS");
	// userSiloId = JSON.parse(userSiloId);
	return (
		<>
			{authResponse ? (
				<Fragment>
					<div className="d-flex align-items-center justify-content-between">
						<h3>Dashboard</h3>
						<p className="mb-0">{authResponse ? authResponse.AccountId : ""}</p>
					</div>
					<Row>
						<Col md={4}>
							<NavLink to="/requests">
								<div className="dashboard-card">
									<div className="card-head">
										<p>Requests</p>
										{/* <span className="up">
											<i className="fa-regular fa-arrow-up-right"></i>
											+1 %
										</span> */}
									</div>
									<div className="card-body">
										<h5 className="mb-0">{requestsCount ? requestsCount.Requests : "-"}</h5>
										<div className="icon">
											<i className="fa-regular fa-hand-point-up"></i>
										</div>
									</div>
								</div>
							</NavLink>
						</Col>
						<Col md={4}>
							<NavLink to="/dashboard">
								<div className="dashboard-card">
									<div className="card-head">
										<p>Assigned Tasks</p>
										{/* <span className="up">
											<i className="fa-regular fa-arrow-up-right"></i>
											+2 %
										</span> */}
									</div>
									<div className="card-body">
										<h5 className="mb-0">{assignedTasksCount ? assignedTasksCount.Requests : "-"}</h5>
										<div className="icon">
											<i className="fa-regular fa-clipboard-list"></i>
										</div>
									</div>
								</div>
							</NavLink>
						</Col>
						<Col md={4}>
							<NavLink to="/dashboard">
								<div className="dashboard-card">
									<div className="card-head">
										<p>Internal Tasks</p>
										{/* <span className="down">
											<i className="fa-regular fa-arrow-down-right"></i>
											-3 %
										</span> */}
									</div>
									<div className="card-body">
										<h5 className="mb-0">{internalTasksCount ? internalTasksCount.Requests : "-"}</h5>
										<div className="icon">
											<i className="fa-regular fa-clipboard-list"></i>
										</div>
									</div>
								</div>
							</NavLink>
						</Col>
						<Col md={4}>
							<NavLink to="/events-request">
								<div className="dashboard-card card-2">
									<div className="card-body">
										<div className="icon">
											<i className="fa-regular fa-file-lines"></i>
										</div>
										<p>Events Request Form</p>
									</div>
								</div>
							</NavLink>
						</Col>
						<Col md={4}>
							<NavLink to="/content">
								<div className="dashboard-card card-2">
									<div className="card-body">
										<div className="icon">
											<i className="fa-regular fa-folder"></i>
										</div>
										<p>Content</p>
									</div>
								</div>
							</NavLink>
						</Col>
						<Col md={4}>
							<NavLink to="/dashboard">
								<div className="dashboard-card card-2">
									<div className="card-body">
										<div className="icon">
											<i className="fa-regular fa-chart-pie"></i>
										</div>
										<p>Reports</p>
									</div>
								</div>
							</NavLink>
						</Col>
						{/* {userSiloId ? (
							userSiloId.usersiloid === 10000000 ||
							userSiloId.usersiloid === 10000002 ||
							userSiloId.usersiloid === 10000003 ||
							userSiloId.usersiloid === 10000009 ||
							userSiloId.usersiloid === 10000010 ||
							userSiloId.usersiloid === 10000011 ||
							userSiloId.usersiloid === 10000014 ||
							userSiloId.usersiloid === 10000017 ||
							userSiloId.usersiloid === 10000020 ||
							userSiloId.usersiloid === 10000023 ||
							userSiloId.usersiloid === 10000026 ||
							userSiloId.usersiloid === 10000029 ||
							userSiloId.usersiloid === 10000032 ||
							userSiloId.usersiloid === 10000035 ||
							userSiloId.usersiloid === 10000038 ||
							userSiloId.usersiloid === 10000049 ? (
								<Col md={6}>
									<NavLink
										to="https://neftbrands.crm.dynamics.com/main.aspx?appid=a5b45d6e-b510-43c9-b82f-6362a71c5cc0&pagetype=dashboard&id=229af978-63cf-464d-b07b-d9e5ad046780&type=system"
										target="_blank"
									>
										<div className="dashboard-card card-2">
											<div className="card-body">
												<div className="icon">
													<i className="fa-regular fa-cart-shopping"></i>
												</div>
												<p>CRM</p>
											</div>
										</div>
									</NavLink>
								</Col>
							) : (
								""
							)
						) : (
							""
						)} */}
						<Col md={6}>
							<NavLink
								to="https://neftbrands.crm.dynamics.com/main.aspx?appid=a5b45d6e-b510-43c9-b82f-6362a71c5cc0&pagetype=dashboard&id=229af978-63cf-464d-b07b-d9e5ad046780&type=system"
								target="_blank"
							>
								<div className="dashboard-card card-2">
									<div className="card-body">
										<div className="icon">
											<i className="fa-regular fa-cart-shopping"></i>
										</div>
										<p>CRM</p>
									</div>
								</div>
							</NavLink>
						</Col>
						<Col md={3}>
							<NavLink to="/pos">
								<div className="dashboard-card card-2">
									<div className="card-body">
										<div className="icon">
											<i className="fa-regular fa-cart-shopping"></i>
										</div>
										<p>POS</p>
									</div>
								</div>
							</NavLink>
						</Col>
						{/* <Col md={4}>
							<NavLink to="/experiences">
								<div className="dashboard-card card-2">
									<div className="card-body">
										<div className="icon">
											<i className="fa-regular fa-clipboard-list-check"></i>
										</div>
										<p>Experiences</p>
									</div>
								</div>
							</NavLink>
						</Col>
						<Col md={4}>
							<NavLink to="/matrices">
								<div className="dashboard-card card-2">
									<div className="card-body">
										<div className="icon">
											<i className="fa-regular fa-grid"></i>
										</div>
										<p>Matrices</p>
									</div>
								</div>
							</NavLink>
						</Col> */}
						<Col md={3}>
							<NavLink to="/messages">
								<div className="dashboard-card card-2">
									<div className="card-body">
										<div className="icon">
											<i className="fa-regular fa-envelope"></i>
										</div>
										<p>Messages</p>
									</div>
								</div>
							</NavLink>
						</Col>
					</Row>
					<div className="widget mb-4">
						<div className="widget-head">Requests</div>
						<div className="widget-body">
							<iframe title="1" src={security ? security.Data[0].SiloSmartsheetURLRequest : ""} frameBorder="0" height={700} width="100%"></iframe>
						</div>
					</div>
					<div className="widget mb-4">
						<div className="widget-head">Archive</div>
						<div className="widget-body">
							<iframe title="2" src={security ? security.Data[0].SiloSmartsheetURLArchive : ""} frameBorder="0" height={700} width="100%"></iframe>
						</div>
					</div>
					<div className="widget mb-4">
						<div className="widget-head">Silo</div>
						<div className="widget-body">
							<iframe title="3" src={security ? security.Data[0].SiloSmartsheetURLSilo : ""} frameBorder="0" height={700} width="100%"></iframe>
						</div>
					</div>
				</Fragment>
			) : (
				<div className="loader-container">
					<div className="loader">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	error: state.error,
	auth: state.auth,
});

export default connect(mapStateToProps, { generateToast })(Dashboard);
