import React from "react";

const Content = () => {
	return (
		<div className="widget">
			<div className="widget-head">Content</div>
			<div className="widget-body">
				<iframe title="1" src="https://brandfolder.com/neftbrands" frameBorder="0" height={700} width="100%"></iframe>
			</div>
		</div>
	);
};

export default Content;
