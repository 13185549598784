import {
	GET_NOTIFICATIONS,
	GET_PERMISSIONS,
	GET_SECURITY,
	GET_SILOREGIONS,
	GET_EVENTMATRIX1,
	GET_EVENTMATRIX2,
	GET_EVENTMATRIX3,
	LOGIN_SUCCESS,
	LOGOUT_SUCCESS,
	PROFILE_IMAGE,
	REQUESTS_COUNT,
	ASSIGNED_TASKS_COUNT,
	INTERNAL_TASKS_COUNT,
} from "../Actions/types";

const intitalState = {
	authResponse: null,
	profileImage: null,
	notifications: null,
	security: null,
	permissions: null,
	eventmatrix: {
		matrix1: null,
		matrix2: null,
		matrix3: null,
	},
	siloregions: null,
	requestsCount: null,
	assignedTasksCount: null,
	internalTasksCount: null,
};

export default function authReducer(state = intitalState, action) {
	switch (action.type) {
		case LOGIN_SUCCESS:
			return {
				...state,
				authResponse: action.payload,
			};
		case PROFILE_IMAGE:
			return {
				...state,
				profileImage: action.payload,
			};
		case GET_NOTIFICATIONS:
			return {
				...state,
				notifications: action.payload,
			};
		case GET_SECURITY:
			return {
				...state,
				security: action.payload,
			};
		case GET_PERMISSIONS:
			return {
				...state,
				permissions: action.payload,
			};
		case GET_EVENTMATRIX1:
			return {
				...state,
				eventmatrix: {
					...state.eventmatrix,
					matrix1: action.payload,
				},
			};
		case GET_EVENTMATRIX2:
			return {
				...state,
				eventmatrix: {
					...state.eventmatrix,
					matrix2: action.payload,
				},
			};
		case GET_EVENTMATRIX3:
			return {
				...state,
				eventmatrix: {
					...state.eventmatrix,
					matrix3: action.payload,
				},
			};
		case GET_SILOREGIONS:
			return {
				...state,
				siloregions: action.payload,
			};
		case REQUESTS_COUNT:
			return {
				...state,
				requestsCount: action.payload,
			};
		case ASSIGNED_TASKS_COUNT:
			return {
				...state,
				assignedTasksCount: action.payload,
			};
		case INTERNAL_TASKS_COUNT:
			return {
				...state,
				internalTasksCount: action.payload,
			};
		case LOGOUT_SUCCESS:
			localStorage.clear();
			return {
				...state,
				authResponse: null,
				profileImage: null,
				messages: null,
				security: null,
				permissions: null,
				eventmatrix: {
					matrix1: null,
					matrix2: null,
					matrix3: null,
				},
				siloregions: null,
				requestsCount: null,
				assignedTasksCount: null,
				internalTasksCount: null,
			};
		default:
			return state;
	}
}
