export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const PROFILE_IMAGE = "PROFILE_IMAGE";

export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const GET_REQUESTS = "GET_REQUESTS";
export const REQUESTS_ERROR = "REQUESTS_ERROR";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_SECURITY = "GET_SECURITY";
export const GET_PERMISSIONS = "GET_PERMISSIONS";
export const GET_EVENTMATRIX1 = "GET_EVENTMATRIX1";
export const GET_EVENTMATRIX2 = "GET_EVENTMATRIX2";
export const GET_EVENTMATRIX3 = "GET_EVENTMATRIX3";

export const GENERATE_TOAST = "GENERATE_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";

export const GET_EVENT_REGIONS = "GET_EVENT_REGIONS";
export const GET_EVENT_TYPES = "GET_EVENT_TYPES";
export const QUESTIONS_REQUEST = "QUESTIONS_REQUEST";
export const REQUEST_OVERRIDE = "REQUEST_OVERRIDE";
export const GET_DISTRIBUTION_ACCOUNTS = "GET_DISTRIBUTION_ACCOUNTS";
export const GET_SILOREGIONS = "GET_SILOREGIONS";
export const FILE_UPLOAD = "FILE_UPLOAD";
export const REQUEST_COST = "REQUEST_COST";
export const REQUEST_NUANCES = "REQUEST_NUANCES";
export const GET_TOPICS = "GET_TOPICS";
export const GET_NOTIFICATIONS_HISTORY = "GET_NOTIFICATIONS_HISTORY";

export const REQUESTS_COUNT = "REQUESTS_COUNT";
export const ASSIGNED_TASKS_COUNT = "ASSIGNED_TASKS_COUNT";
export const INTERNAL_TASKS_COUNT = "INTERNAL_TASKS_COUNT";
export const GET_TOPIC_MESSAGES = "GET_TOPIC_MESSAGES";

export const EVENT_ASSETS_1 = "EVENT_ASSETS_1";
export const EVENT_ASSETS_2 = "EVENT_ASSETS_2";
export const EVENT_ASSETS_3 = "EVENT_ASSETS_3";

export const POS_PRODUCTS = "POS_PRODUCTS";
export const POS_APPROVAL_LIST = "POS_APPROVAL_LIST";

export const API_ENDPOINT = "https://neftinternal.com/";
