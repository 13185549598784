import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { getPOSApprovals, productApproval } from "../../Actions/allActions";

const POSApprovals = ({ all: { posApprovals }, getPOSApprovals, productApproval }) => {
	const [approvals, setApprovals] = useState([]);
	const [loading, setLoading] = useState(false);
	// const [approvalLoader, setApprovalLoader] = useState(false);
	// let Products = [
	// 	{
	// 		id: "10001",
	// 		title: "French & Famous",
	// 		description: "French & Famous Event",
	// 		category: "Events",
	// 		type: "Event: Standup: All Relevant Silos",
	// 		status: "Request: PreSubmit",
	// 	},
	// ];

	useEffect(() => {
		getPOSApprovals(localStorage.getItem("token"));
	}, [getPOSApprovals]);

	useEffect(() => {
		setLoading(true);
		if (posApprovals) {
			setApprovals(posApprovals.TableData);
			setLoading(false);
		}
	}, [posApprovals, approvals]);

	const productApprovalEvent = async (e, data) => {
		//console.log(id, "id");
		e.preventDefault();
		setLoading(true);
		let approvalProductData = {
			itemid: data.ItemId,
			itemqty: data.ItemQty,
			userid: data.RequestUserId,
		};
		const res = await productApproval(localStorage.getItem("token"), approvalProductData);
		if (res) {
			getPOSApprovals(localStorage.getItem("token"));
			setLoading(false);
		}
	};

	const approveBtnTemplate = (data) => {
		//console.log(data, "===============");
		return (
			<button className="btn btn-main btn-sm" onClick={(e) => productApprovalEvent(e, data)}>
				Approve
			</button>
		);
	};

	let userSiloId = localStorage.getItem("IDS");
	userSiloId = JSON.parse(userSiloId);

	return (
		<>
			{userSiloId ? (
				userSiloId.usersiloid === 10000049 ? (
					<div className="widget">
						<div className="widget-head">POS Approvals</div>
						<div className="widget-body">
							<DataTable
								value={approvals}
								showGridlines
								paginator
								rows={5}
								rowsPerPageOptions={[5, 10, 25, 50]}
								loading={loading}
								//paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
							>
								<Column field="ItemId" header="ID"></Column>
								<Column field="ItemName" header="Product Item"></Column>
								<Column field="ItemQty" header="Product Quantity"></Column>
								<Column field="RequestUserName" header="Product Request By"></Column>
								<Column body={approveBtnTemplate} header="Action"></Column>
							</DataTable>
						</div>
					</div>
				) : (
					""
				)
			) : (
				""
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	error: state.error,
	all: state.all,
});

export default connect(mapStateToProps, { getPOSApprovals, productApproval })(POSApprovals);
