import axios from "axios";
import { returnErrors } from "./errorActions";
import {
	GET_REQUESTS,
	REQUESTS_ERROR,
	API_ENDPOINT,
	GENERATE_TOAST,
	REMOVE_TOAST,
	GET_EVENT_REGIONS,
	GET_EVENT_TYPES,
	GET_DISTRIBUTION_ACCOUNTS,
	QUESTIONS_REQUEST,
	REQUEST_OVERRIDE,
	FILE_UPLOAD,
	REQUEST_COST,
	REQUEST_NUANCES,
	GET_TOPICS,
	GET_NOTIFICATIONS_HISTORY,
	GET_TOPIC_MESSAGES,
	EVENT_ASSETS_1,
	EVENT_ASSETS_2,
	EVENT_ASSETS_3,
	POS_PRODUCTS,
	POS_APPROVAL_LIST,
} from "./types";

let toast_body = [];

export const generateToast = (type, text) => (dispatch) => {
	toast_body.push({
		type: type,
		text: text,
	});
	dispatch({
		type: GENERATE_TOAST,
		payload: toast_body,
	});
	setTimeout(() => {
		toast_body.pop();
		dispatch({
			type: REMOVE_TOAST,
			payload: 0,
		});
	}, 5000);
	return true;
};

export const removeToast = (toast) => (dispatch) => {
	console.log(toast);
	toast_body = toast_body.filter((val, i) => i !== toast);
	dispatch({
		type: REMOVE_TOAST,
		payload: toast,
	});
	return true;
};

export const getRequests = (token) => async (dispatch) => {
	let ids = localStorage.getItem("IDS");
	let api_keys = JSON.parse(ids);
	const body = {
		callersilo: api_keys.usersiloid,
		callerregion: api_keys.userreginoid,
	};
	const config = {
		headers: {
			"Content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};

	try {
		const resp = await axios.post(API_ENDPOINT + "api/v/1/requestlist", body, config);
		if (resp.status === 200) {
			dispatch({
				type: GET_REQUESTS,
				payload: resp.data,
			});
			return true;
		}
	} catch (err) {
		dispatch({
			type: REQUESTS_ERROR,
		});
		if (!err.response) {
			dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
		} else if (err.response.status === 401) {
			dispatch(generateToast("fail", "Unauthorized"));
		} else {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch(generateToast("fail", err.response.data.Status));
		}

		return false;
	}
};

export const getEventRegions = (token) => async (dispatch) => {
	let ids = localStorage.getItem("IDS");
	let api_keys = JSON.parse(ids);
	const body = {
		callersilo: api_keys.usersiloid,
		callerregion: api_keys.userreginoid,
	};
	const config = {
		headers: {
			"Content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};
	try {
		const resp = await axios.post(API_ENDPOINT + "api/v/1/availableregions", body, config);
		if (resp.status === 200) {
			dispatch({
				type: GET_EVENT_REGIONS,
				payload: resp.data,
			});
			return true;
		}
	} catch (err) {
		dispatch({
			type: REQUESTS_ERROR,
		});
		if (!err.response) {
			dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
		} else if (err.response.status === 401) {
			dispatch(generateToast("fail", "Unauthorized"));
		} else {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch(generateToast("fail", err.response.data.Status));
		}

		return false;
	}
};

export const getEventTypes = (token, regionId) => async (dispatch) => {
	let ids = localStorage.getItem("IDS");
	let api_keys = JSON.parse(ids);
	const body = {
		callersilo: api_keys.usersiloid,
		callerregion: api_keys.userreginoid,
		region: regionId,
	};
	const config = {
		headers: {
			"Content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};
	try {
		const resp = await axios.post(API_ENDPOINT + "api/v/1/regionevents", body, config);
		if (resp.status === 200) {
			dispatch({
				type: GET_EVENT_TYPES,
				payload: resp.data,
			});
			return true;
		}
	} catch (err) {
		dispatch({
			type: REQUESTS_ERROR,
		});
		if (!err.response) {
			dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
		} else if (err.response.status === 401) {
			dispatch(generateToast("fail", "Unauthorized"));
		} else {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch(generateToast("fail", err.response.data.Status));
		}

		return false;
	}
};

export const questionsEvent = (token, data) => async (dispatch) => {
	const body = data;
	const config = {
		headers: {
			"Content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};

	try {
		const resp = await axios.post(API_ENDPOINT + "api/v/2/requestanswer", body, config);
		if (resp.status === 200) {
			dispatch({
				type: QUESTIONS_REQUEST,
				payload: resp.data,
			});
			dispatch(generateToast("success", "Questions Form Completed"));
			return true;
		}
	} catch (err) {
		dispatch({
			type: REQUESTS_ERROR,
		});
		if (!err.response) {
			dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
		} else if (err.response.status === 401) {
			dispatch(generateToast("fail", "Unauthorized"));
		} else {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch(generateToast("fail", err.response.data.Status));
		}

		return false;
	}
};

export const requestOverrideEvent = (token, data) => async (dispatch) => {
	const body = data;
	const config = {
		headers: {
			"Content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};

	try {
		const resp = await axios.post(API_ENDPOINT + "api/v/2/requestoverride", body, config);
		if (resp.status === 200) {
			dispatch({
				type: REQUEST_OVERRIDE,
				payload: resp.data,
			});
			return true;
		}
	} catch (err) {
		dispatch({
			type: REQUESTS_ERROR,
		});
		if (!err.response) {
			dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
		} else if (err.response.status === 401) {
			dispatch(generateToast("fail", "Unauthorized"));
		} else {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch(generateToast("fail", err.response.data.Status));
		}

		return false;
	}
};

export const getDistributionAccount = (token) => async (dispatch) => {
	let ids = localStorage.getItem("IDS");
	let api_keys = JSON.parse(ids);
	const body = {
		callersilo: api_keys.usersiloid,
		callerregion: api_keys.userreginoid,
	};
	const config = {
		headers: {
			"Content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};

	try {
		const resp = await axios.post(API_ENDPOINT + "api/v/1/distributionaccounts", body, config);
		if (resp.status === 200) {
			dispatch({
				type: GET_DISTRIBUTION_ACCOUNTS,
				payload: resp.data,
			});
			return true;
		}
	} catch (err) {
		dispatch({
			type: REQUESTS_ERROR,
		});
		if (!err.response) {
			dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
		} else if (err.response.status === 401) {
			dispatch(generateToast("fail", "Unauthorized"));
		} else {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch(generateToast("fail", err.response.data.Status));
		}

		return false;
	}
};

export const FileUploadEvent = (token, data) => async (dispatch) => {
	const body = data;
	const config = {
		headers: {
			"Content-type": "multipart/form-data",
			Authorization: `Bearer ${token}`,
		},
	};

	try {
		const resp = await axios.post(API_ENDPOINT + "api/v/2/upload", body, config);
		if (resp.status === 200) {
			console.log("done");
			dispatch({
				type: FILE_UPLOAD,
				payload: resp.data,
			});
			dispatch(generateToast("success", resp.data.File));
			return true;
		}
	} catch (err) {
		dispatch({
			type: REQUESTS_ERROR,
		});
		if (!err.response) {
			dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
		} else if (err.response.status === 401) {
			dispatch(generateToast("fail", "Unauthorized"));
		} else {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch(generateToast("fail", err.response.data.Status));
		}

		return false;
	}
};

export const requestCost = (token, data) => async (dispatch) => {
	const body = data;
	const config = {
		headers: {
			"Content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};

	try {
		const resp = await axios.post(API_ENDPOINT + "api/v/2/requestcost", body, config);
		if (resp.status === 200) {
			dispatch({
				type: REQUEST_COST,
				payload: resp.data,
			});
			dispatch(generateToast("success", "Cost Form Completed"));
			return true;
		}
	} catch (err) {
		dispatch({
			type: REQUESTS_ERROR,
		});
		if (!err.response) {
			dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
		} else if (err.response.status === 401) {
			dispatch(generateToast("fail", "Unauthorized"));
		} else {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch(generateToast("fail", err.response.data.Status));
		}

		return false;
	}
};

export const requestNuances = (token, data) => async (dispatch) => {
	const body = data;
	const config = {
		headers: {
			"Content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};

	try {
		const resp = await axios.post(API_ENDPOINT + "api/v/2/requestnuances", body, config);
		if (resp.status === 200) {
			dispatch({
				type: REQUEST_NUANCES,
				payload: resp.data,
			});
			dispatch(generateToast("success", "Nuances Form Completed"));
			return true;
		}
	} catch (err) {
		dispatch({
			type: REQUESTS_ERROR,
		});
		if (!err.response) {
			dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
		} else if (err.response.status === 401) {
			dispatch(generateToast("fail", "Unauthorized"));
		} else {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch(generateToast("fail", err.response.data.Status));
		}

		return false;
	}
};

export const requestEvent = (form, token, title) => async (dispatch) => {
	const config = {
		headers: {
			"Content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};
	try {
		const resp = await axios.post(API_ENDPOINT + `api/v/2/requestevent`, form, config);
		if (resp.status === 200) {
			dispatch(generateToast("success", title + " Form Completed"));
			return true;
		}
		return false;
	} catch (err) {
		dispatch({
			type: REQUESTS_ERROR,
		});
		if (!err.response) {
			dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
		} else if (err.response.status === 401) {
			dispatch(generateToast("fail", "Unauthorized"));
		} else {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch(generateToast("fail", err.response.data.Status));
		}

		return false;
	}
};

export const getTopics = (token) => async (dispatch) => {
	const config = {
		headers: {
			"Content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};
	const body = {};
	try {
		const resp = await axios.post(API_ENDPOINT + "api/v/2/gettopics", body, config);
		if (resp.status === 200) {
			dispatch({
				type: GET_TOPICS,
				payload: resp.data,
			});
			return true;
		}
	} catch (err) {
		dispatch({
			type: REQUESTS_ERROR,
		});
		if (!err.response) {
			dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
		} else if (err.response.status === 401) {
			dispatch(generateToast("fail", "Unauthorized"));
		} else {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch(generateToast("fail", err.response.data.Status));
		}

		return false;
	}
};

export const getNotificationsHistory = (token) => async (dispatch) => {
	let ids = localStorage.getItem("IDS");
	let api_keys = JSON.parse(ids);
	const body = {
		userid: api_keys.usersiloid,
	};
	const config = {
		headers: {
			"Content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};

	try {
		const resp = await axios.post(API_ENDPOINT + "api/v/2/notificationhistory", body, config);
		if (resp.status === 200) {
			dispatch({
				type: GET_NOTIFICATIONS_HISTORY,
				payload: resp.data,
			});
			return true;
		}
	} catch (err) {
		dispatch({
			type: REQUESTS_ERROR,
		});
		if (!err.response) {
			dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
		} else if (err.response.status === 401) {
			dispatch(generateToast("fail", "Unauthorized"));
		} else {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch(generateToast("fail", err.response.data.Status));
		}

		return false;
	}
};

export const createNotificataion = (token, body) => async (dispatch) => {
	const config = {
		headers: {
			"Content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};

	try {
		const resp = await axios.post(API_ENDPOINT + "api/v/2/createnotifications", body, config);
		if (resp.status === 200) {
			dispatch(generateToast("success", "Notification Send Successfully!"));
			return true;
		}
	} catch (err) {
		dispatch({
			type: REQUESTS_ERROR,
		});
		if (!err.response) {
			dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
		} else if (err.response.status === 401) {
			dispatch(generateToast("fail", "Unauthorized"));
		} else {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch(generateToast("fail", err.response.data.Status));
		}

		return false;
	}
};

export const createTopic = (token, body) => async (dispatch) => {
	const config = {
		headers: {
			"Content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};

	try {
		const resp = await axios.post(API_ENDPOINT + "api/v/2/createtopics", body, config);
		if (resp.status === 200) {
			dispatch(generateToast("success", "Topic Created Successfully!"));
			return resp.data;
		}
	} catch (err) {
		dispatch({
			type: REQUESTS_ERROR,
		});
		if (!err.response) {
			dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
		} else if (err.response.status === 401) {
			dispatch(generateToast("fail", "Unauthorized"));
		} else {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch(generateToast("fail", err.response.data.Status));
		}

		return false;
	}
};

export const getTopicMessages = (token, topic_id) => async (dispatch) => {
	let ids = localStorage.getItem("IDS");
	let api_keys = JSON.parse(ids);
	const body = {
		callersilo: api_keys.usersiloid,
		topicid: topic_id,
	};
	const config = {
		headers: {
			"Content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};

	try {
		const resp = await axios.post(API_ENDPOINT + "api/v/2/getmsgbytopics", body, config);
		if (resp.status === 200) {
			dispatch({
				type: GET_TOPIC_MESSAGES,
				payload: resp.data,
			});
			return true;
		}
	} catch (err) {
		dispatch({
			type: REQUESTS_ERROR,
		});
		if (!err.response) {
			dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
		} else if (err.response.status === 401) {
			dispatch(generateToast("fail", "Unauthorized"));
		} else {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch(generateToast("fail", err.response.data.Status));
		}

		return false;
	}
};

export const eventAssets1 = (form, token) => async (dispatch) => {
	const config = {
		headers: {
			"Content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};
	try {
		const resp = await axios.post(API_ENDPOINT + `api/v/1/eventasset1`, form, config);
		if (resp.status === 200) {
			//dispatch(generateToast("success", "Event Asset 1"));
			dispatch({
				type: EVENT_ASSETS_1,
				payload: resp.data,
			});
			return true;
		}
		return false;
	} catch (err) {
		dispatch({
			type: REQUESTS_ERROR,
		});
		if (!err.response) {
			dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
		} else if (err.response.status === 401) {
			dispatch(generateToast("fail", "Unauthorized"));
		} else {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch(generateToast("fail", err.response.data.Status));
		}

		return false;
	}
};

export const eventAssets2 = (form, token) => async (dispatch) => {
	const config = {
		headers: {
			"Content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};
	try {
		const resp = await axios.post(API_ENDPOINT + `api/v/1/eventasset2`, form, config);
		if (resp.status === 200) {
			//dispatch(generateToast("success", "Event Asset 2"));
			dispatch({
				type: EVENT_ASSETS_2,
				payload: resp.data,
			});
			return true;
		}
		return false;
	} catch (err) {
		dispatch({
			type: REQUESTS_ERROR,
		});
		if (!err.response) {
			dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
		} else if (err.response.status === 401) {
			dispatch(generateToast("fail", "Unauthorized"));
		} else {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch(generateToast("fail", err.response.data.Status));
		}

		return false;
	}
};

export const eventAssets3 = (form, token) => async (dispatch) => {
	const config = {
		headers: {
			"Content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};
	try {
		const resp = await axios.post(API_ENDPOINT + `api/v/1/eventasset3`, form, config);
		if (resp.status === 200) {
			//dispatch(generateToast("success", "Event Asset 3"));
			dispatch({
				type: EVENT_ASSETS_3,
				payload: resp.data,
			});
			return true;
		}
		return false;
	} catch (err) {
		dispatch({
			type: REQUESTS_ERROR,
		});
		if (!err.response) {
			dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
		} else if (err.response.status === 401) {
			dispatch(generateToast("fail", "Unauthorized"));
		} else {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch(generateToast("fail", err.response.data.Status));
		}

		return false;
	}
};

export const getPOSProducts = (token) => async (dispatch) => {
	const config = {
		headers: {
			"Content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};
	const body = {};
	try {
		const resp = await axios.post(API_ENDPOINT + "api/v/2/getposdata", body, config);
		if (resp.status === 200) {
			dispatch({
				type: POS_PRODUCTS,
				payload: resp.data,
			});
			return true;
		}
	} catch (err) {
		dispatch({
			type: REQUESTS_ERROR,
		});
		if (!err.response) {
			dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
		} else if (err.response.status === 401) {
			dispatch(generateToast("fail", "Unauthorized"));
		} else {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch(generateToast("fail", err.response.data.Status));
		}

		return false;
	}
};

export const purchasePOSProduct = (token, body) => async (dispatch) => {
	const config = {
		headers: {
			"Content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};

	try {
		const resp = await axios.post(API_ENDPOINT + "api/v/2/updatetable", body, config);
		if (resp.status === 200) {
			dispatch(generateToast("success", "Your Purchase has been sent for approval!"));
			return true;
		}
	} catch (err) {
		dispatch({
			type: REQUESTS_ERROR,
		});
		if (!err.response) {
			dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
		} else if (err.response.status === 401) {
			dispatch(generateToast("fail", "Unauthorized"));
		} else {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch(generateToast("fail", err.response.data.Status));
		}

		return false;
	}
};

export const getPOSApprovals = (token) => async (dispatch) => {
	const config = {
		headers: {
			"Content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};
	const body = {};
	try {
		const resp = await axios.post(API_ENDPOINT + "api/v/2/posrequests", body, config);
		if (resp.status === 200) {
			dispatch({
				type: POS_APPROVAL_LIST,
				payload: resp.data,
			});
			return true;
		}
	} catch (err) {
		dispatch({
			type: REQUESTS_ERROR,
		});
		if (!err.response) {
			dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
		} else if (err.response.status === 401) {
			dispatch(generateToast("fail", "Unauthorized"));
		} else {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch(generateToast("fail", err.response.data.Status));
		}

		return false;
	}
};

export const productApproval = (token, body) => async (dispatch) => {
	const config = {
		headers: {
			"Content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};

	try {
		const resp = await axios.post(API_ENDPOINT + "api/v/2/posapprovals", body, config);
		console.log(resp, "resp");
		if (resp.status === 200) {
			dispatch(generateToast("success", resp.data.Status));
			return true;
		}
	} catch (err) {
		dispatch({
			type: REQUESTS_ERROR,
		});
		if (!err.response) {
			dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
		} else if (err.response.status === 401) {
			dispatch(generateToast("fail", "Unauthorized"));
		} else {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch(generateToast("fail", err.response.data.Status));
		}

		return false;
	}
};
