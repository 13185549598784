import React, { useState, useEffect } from "react";
import { Form, Row, Col, Spinner } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { connect } from "react-redux";
import { getTopics, getNotificationsHistory, createNotificataion, createTopic, getTopicMessages } from "../../Actions/allActions";
import { getNotifications } from "../../Actions/authActions";
import dayjs from "dayjs";

const Messages = ({
	all: { topics, notificationsHistory, topicMessages },
	auth: { notifications },
	getTopics,
	getNotificationsHistory,
	createNotificataion,
	createTopic,
	getNotifications,
	getTopicMessages,
}) => {
	const [topicField, setTopicField] = useState(false);
	const [validated, setValidated] = useState(false);
	const [topiclist, setTopicList] = useState([]);
	const [messageForm, setMessageForm] = useState({
		notification: "",
		userid: JSON.parse(localStorage.getItem("IDS")).usersiloid,
		topicid: null,
	});
	const [notificationsHistoryList, setNotificationsHistoryList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [createTopicForm, setCreateTopicForm] = useState({
		topicname: "",
	});
	const [formLoader, setFormLoader] = useState(false);
	const [messagesList, setMessagesList] = useState([]);
	const [msgLoader, setMsgLoader] = useState(false);

	const MessageFormSubmit = async (e) => {
		setFormLoader(true);
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
			e.preventDefault();
			e.stopPropagation();
			console.log("false");
		}
		setValidated(true);
		if (form.checkValidity()) {
			//console.log("true");
			//console.log(messageForm, "messageForm");
			e.preventDefault();
			e.stopPropagation();
			let topic_res_keys;
			if (topicField) {
				const topic_res = await createTopic(localStorage.getItem("token"), createTopicForm);
				topic_res_keys = Object.keys(topic_res.TopicCreated);
				await createNotificataion(localStorage.getItem("token"), { ...messageForm, topicid: parseInt(topic_res_keys[0]) });
				getNotifications(localStorage.getItem("token"));
				setLoading(true);
				getNotificationsHistory(localStorage.getItem("token"));
			} else {
				await createNotificataion(localStorage.getItem("token"), messageForm);
				getNotifications(localStorage.getItem("token"));
				setLoading(true);
				getNotificationsHistory(localStorage.getItem("token"));
			}
			//console.log(res, "res");
			setFormLoader(false);
		}
	};
	useEffect(() => {
		getTopics(localStorage.getItem("token"));
	}, [getTopics]);

	useEffect(() => {
		getNotificationsHistory(localStorage.getItem("token"));
	}, [getNotificationsHistory]);

	useEffect(() => {
		//console.log(topiclist);
		if (topics) {
			setTopicList(topics);
		}
	}, [topics]);

	useEffect(() => {
		if (topicMessages) {
			setMessagesList(topicMessages.Notifications);
		}
	}, [topicMessages]);

	useEffect(() => {
		//console.log(notificationsHistoryList);
		setLoading(true);
		if (notificationsHistory) {
			setNotificationsHistoryList(notificationsHistory.Notifications);
			setLoading(false);
		}
	}, [notificationsHistory]);

	const dateTemplate = (data) => {
		return `${dayjs(data.Date).format("DD MMMM YYYY hh:mm:ss A")}`;
	};

	const replyBtnTemplate = (data) => {
		return (
			<button className="btn primary btn-sm" disabled>
				Reply <i className="fa-regular fa-reply ps-1"></i>
			</button>
		);
	};

	const GetTopicMessages = async (e, topic_id) => {
		let msgLinks = document.getElementsByClassName("msg-link");
		for (let i = 0; i < msgLinks.length; i++) {
			msgLinks[i].classList.remove("active");
		}
		e.currentTarget.className += " active";
		setMsgLoader(true);
		await getTopicMessages(localStorage.getItem("token"), topic_id);
		setMsgLoader(false);
	};
	return (
		<>
			<div className="widget">
				<div className="widget-head">Messages</div>
				<div className="widget-body">
					<Form noValidate validated={validated} onSubmit={MessageFormSubmit}>
						<Row>
							<Col lg={3}>
								<Form.Label>
									Topics <small className="text-danger">*</small>
								</Form.Label>
								{topicField ? (
									<>
										<Form.Control
											type="text"
											required
											name="topicname"
											onChange={(e) => {
												setCreateTopicForm({
													...createTopicForm,
													[e.target.name]: e.target.value,
												});
											}}
										/>
										<Form.Control.Feedback type="invalid">Please Enter Topic Name</Form.Control.Feedback>
									</>
								) : (
									<>
										<Form.Select
											defaultValue=""
											required
											name="topicid"
											onChange={(e) => {
												setMessageForm({
													...messageForm,
													[e.target.name]: parseInt(e.target.value),
												});
											}}
										>
											<option value="" disabled>
												Select Topic Name...
											</option>
											{topiclist
												? topiclist.length > 0
													? topiclist.map((i, v) => (
															<option key={v} value={i.id}>
																{i.topic}
															</option>
													  ))
													: ""
												: ""}
										</Form.Select>
										<Form.Control.Feedback type="invalid">Please Select Topic Name</Form.Control.Feedback>
									</>
								)}
							</Col>
							<Col lg={3} className="d-flex align-items-end">
								{topicField ? (
									<button type="button" className="btn btn-main next" onClick={() => setTopicField(false)}>
										Existing Topics <i className="fa-regular fa-check"></i>
									</button>
								) : (
									<button type="button" className="btn btn-main next" onClick={() => setTopicField(true)}>
										New Topic <i className="fa-regular fa-plus"></i>
									</button>
								)}
							</Col>
							<Col lg={12} className="my-3">
								<Form.Label>
									Message <small className="text-danger">*</small>
								</Form.Label>
								<Form.Control
									as="textarea"
									rows={4}
									name="notification"
									required
									onChange={(e) => {
										setMessageForm({
											...messageForm,
											[e.target.name]: e.target.value,
										});
									}}
								/>
								<Form.Control.Feedback type="invalid">Please Enter Message</Form.Control.Feedback>
							</Col>
						</Row>
						<div className="d-flex align-items-start justify-content-end gap-3 mt-4">
							<button type="submit" className="btn btn-main next" disabled={formLoader}>
								{formLoader ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Submit"}
							</button>
						</div>
					</Form>
				</div>
			</div>
			<div className="widget">
				<div className="widget-head">Received Messages</div>
				<div className="widget-body">
					<div className="msg-viewer">
						<div className="row">
							<div className="col-lg-3 border-end">
								<div className="msg-list">
									<h6>Messages</h6>
									<ul>
										{topics ? (
											topiclist.length === 0 ? (
												<li>No Topics!</li>
											) : (
												topiclist.map((i, v) => (
													<li key={v} className="msg-link" onClick={(e) => GetTopicMessages(e, i.id)}>
														{i.topic}
													</li>
												))
											)
										) : (
											<li className="text-center msg-loader">
												<Spinner as="span" animation="border" role="status" aria-hidden="true" />
											</li>
										)}
									</ul>
								</div>
							</div>
							<div className="col-lg-9">
								<div className="msg-body">
									<DataTable value={messagesList} loading={msgLoader} showGridlines paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}>
										<Column field="msg" header="Message"></Column>
										<Column body={replyBtnTemplate} header="Action"></Column>
									</DataTable>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="widget">
				<div className="widget-head">Sent Messages</div>
				<div className="widget-body">
					<DataTable
						value={notificationsHistoryList}
						sortField="Date"
						sortOrder={-1}
						loading={loading}
						showGridlines
						paginator
						rows={5}
						rowsPerPageOptions={[5, 10, 25, 50]}
					>
						<Column field="TopicId" sortable header="Topic"></Column>
						<Column field="Message" sortable header="Message Sent"></Column>
						<Column body={dateTemplate} field="Date" sortable header="Date"></Column>
					</DataTable>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	error: state.error,
	all: state.all,
	auth: state.auth,
});

export default connect(mapStateToProps, { getTopics, getNotificationsHistory, createNotificataion, createTopic, getNotifications, getTopicMessages })(
	Messages
);
