import React, { useEffect, useState } from "react";
import { Tabs, Tab, Form, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { getEventRegions, requestEvent, getEventTypes, getDistributionAccount, questionsEvent, requestOverrideEvent, FileUploadEvent, requestCost, requestNuances, generateToast} from "../../Actions/allActions";
import dayjs from "dayjs";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";

const EventsRequest = ({ all: { eventRegions, eventTypes, distributionAccounts}, getEventRegions, getEventTypes, requestEvent, getDistributionAccount, questionsEvent, requestOverrideEvent, generateToast, FileUploadEvent, requestCost, requestNuances}) => {
	const ids = localStorage.getItem("IDS");
	const api_keys = JSON.parse(ids);	
	const documentTypes = ["Legal:Venue Contract","Legal:Promoter Contract","Legal:Catering Contract","Legal:Talent Contract","Legal:Vendor Contract","Event:Experience Image","Event:Experience Video","Event:Agenda","Event:Drink Menu","Event:Inventory Assets By Macro","Event:Inventory Drinks","Event:Inventory Ingredients","Legal:Venue Contract Executed","Legal:Promoter Contract Executed","Legal:Catering Contract Executed","Legal:Talent COntract Executed","Legal:Vender Contract Executed","Event:Inventory Assets By Micro"];
	const nuancesTypes = ["Plus One","VIP Status","VVIP Status","Door Access","Bar Access","Parking Pass","Table Service - 2 Guests","Table Service - 4 Guests","Table Service - 6 Guests","Table Service - 8 Guests","Table Service - 10 Guests","Table Service - 12 Guests","Table Service - 16 Guests","Table Service - 20 Guests"]
	const costTypes = ["Event Venue Cost","Event Insurance Cost"];

	const [eventRegionsList, setEventRegionsList] = useState(null);
	const [eventTypesList, setEventTypesList] = useState(null);
	const [validated, setValidated] = useState(false);
	const [tabChange, setTabChange] = useState("summary");
	const [activeTabs, setActiveTabs] = useState({
		summary: false,
		questions: false,
		account: false,
		address: false,
		documents: false,
		costs: false,
		nuances: false,
		postmartem: false,
	});
	const [summaryForm, setSummaryForm] = useState({
		callersilo: api_keys.usersiloid,
		callerregion: api_keys.userreginoid,
		taskrequest: 10000001,
		tasktitle: "",
		taskdescription: "",
		eventregion: 0,
		eventtype: 0,
		eventdatetimestart: "",
		eventdatetimeend: "",
		eventtemperaturefahrenheit: 0,
		eventtemperaturerain: null,
		eventbaronsite: null,
		eventaudiencecount: 0,
		eventnotes: "",
	});
	const [questionsForm, setQuestionsForm] = useState({
		callersilo: api_keys.usersiloid,
		callerregion: api_keys.userreginoid,
		tasktype:10000001,
		taskrequest:10000001,
		questionrequest:10000001,
		questionanswer1:null,
		questionanswer2:null,
		questionanswer3:null,
		questionanswer4:null,
		questionanswer5:null,
		questionanswer6:null
	});
	const [addressForm, setAddressForm] = useState({
		callersilo: api_keys.usersiloid,
		callerregion: api_keys.userreginoid,
		taskrequest: 10000001,
		addresscity: "",
		addresscountryregion: "",
		addresscounty: "",
		addressstateprovince: "",
		addressstreet1: "",
		addressstreet2: "",
		addressstreet3: "",
		addresszippostalcode: ""
	});
	const [uploadForm, setUploadForm] = useState({
		file:null
	});
	const [costForm, setCostForm] = useState({
		callersilo: api_keys.usersiloid,
		callerregion: api_keys.userreginoid,
		taskrequest: 10000001,
		costtype: 10000001,
		costamount: 0
	});
	const [nuancesForm, setNuancesForm] = useState({
		callersilo: api_keys.usersiloid,
		callerregion: api_keys.userreginoid,
		taskrequest: 10000001,
		nuancetype: 10000001,
		nuancemaximum: 0,
		nuanceprice: 0
	});


	const [country,setCountry] = useState(null);

	const [managementOverRide, setmanagementOverRide] = useState("");
	const [distributionAccount, setDistributionAccounts] = useState([]);

	const [isDisabled, setIsDisabled] = useState(true);
	const [selectedAccount, setSelectedAccount] = useState(null);

	const handleSelect = (key) => {
		setTabChange(key);
	};

	const tabChangeForm = (e, next, previous, current, type) => {
		e.preventDefault();
		if (type === "next") {
			setActiveTabs({
				...activeTabs,
				[current]: true,
			});
			setTabChange(next);
		} else {
			setActiveTabs({
				...activeTabs,
				[current]: false,
			});
			setTabChange(previous);
		}
	};

	const SummaryFormSubmit = async (e) => {
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
			e.preventDefault();
			e.stopPropagation();
			console.log("false");
		}
		setValidated(true);
		if (form.checkValidity()) {
			console.log("true");
			console.log(summaryForm, "summaryForm");
			summaryForm.eventdatetimestart = dayjs(summaryForm.eventdatetimestart).format("YYYY-MM-DD HH:mm:ss");
			summaryForm.eventdatetimeend = dayjs(summaryForm.eventdatetimeend).format("YYYY-MM-DD HH:mm:ss");
			summaryForm.eventtemperaturerain = summaryForm.eventtemperaturerain === "true" ? true : false;
			summaryForm.eventbaronsite = summaryForm.eventbaronsite === "true" ? true : false;
			e.preventDefault();
			e.stopPropagation();
			const res = await requestEvent(summaryForm, localStorage.getItem("token"),"Summary");
			console.log(res, "res");
			if (res) {
				tabChangeForm(e, "questions", "", "summary", "next");
				setValidated(false);
			}
		}
	};

	const QuestionsFormSubmit = async (e) => {
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
			e.preventDefault();
			e.stopPropagation();
			console.log("false");
		}
		setValidated(true);
		if (form.checkValidity()) {
			console.log("true");
			console.log(questionsForm, "questionsForm");
			// questionsForm.questionanswer1 = questionsForm.questionanswer1 === "true" ? true : false;
			// questionsForm.questionanswer2 = questionsForm.questionanswer2 === "true" ? true : false;
			// questionsForm.questionanswer3 = questionsForm.questionanswer3 === "true" ? true : false;
			// questionsForm.questionanswer4 = questionsForm.questionanswer4 === "true" ? true : false;
			// questionsForm.questionanswer5 = questionsForm.questionanswer5 === "true" ? true : false;
			// questionsForm.questionanswer6 = questionsForm.questionanswer6 === "true" ? true : false;
			// setmanagementOverRide(managementOverRide === "true" ? true : false);
			e.preventDefault();
			e.stopPropagation();
			const res = await questionsEvent(localStorage.getItem("token"),questionsForm);
			console.log(res, "res");
			console.log(managementOverRide);
			if(managementOverRide === "true" || managementOverRide === "false"){
				let data = {    
					"callersilo": api_keys.usersiloid,
					"callerregion": api_keys.userreginoid,
					"taskrequest":10000001,
					"questionrequest":10000006,
					"questionanswer":managementOverRide === "true" ? true : false
				}
				await requestOverrideEvent(localStorage.getItem("token"),data);
			}
			if (res) {
				tabChangeForm(e, "account", "summary", "questions", "next");
				getDistributionAccount(localStorage.getItem("token"));
				setValidated(false);
			} 

		}
	}

	const AccountSubmit = async (e) => {
		
			// const res = await requestEvent(summaryForm, localStorage.getItem("token"));
			// console.log(res, "res");
			if (selectedAccount != null) {
				tabChangeForm(e, "address", "questions", "account", "next")
				setValidated(false);
			} else {
				generateToast("fail", "Select Any Row");
				console.log("Select Any Row");
			}
	}
	
	const AddressFormSubmit = async (e) => {
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
			e.preventDefault();
			e.stopPropagation();
			console.log("false");
		}
		setValidated(true);
		if (form.checkValidity()) {
			console.log("true");
			addressForm.addresscounty = addressForm.addressstateprovince;
			console.log(addressForm, "adsForm");
			e.preventDefault();
			e.stopPropagation();
			const res = await requestEvent(addressForm, localStorage.getItem("token"),"Address");
			console.log(res, "res");
			if (res) {
				tabChangeForm(e, "documents", "account", "address", "next")
				setValidated(false);
			}
		}
	};

	const UploadFormSubmit = async (e) => {
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
			e.preventDefault();
			e.stopPropagation();
			console.log("false");
		}
		setValidated(true);
		if (form.checkValidity()) {
			console.log("true");
			e.preventDefault();
			e.stopPropagation();
			const formData = new FormData();
			formData.append("file", uploadForm.file);
			const res = await FileUploadEvent(localStorage.getItem("token"),formData);
			console.log(res, "res");
			if (res) {
				tabChangeForm(e, "costs", "address", "documents", "next")
				setValidated(false);
			}
		}
	};

	const CostFormSubmit = async (e) => {
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
			e.preventDefault();
			e.stopPropagation();
			console.log("false");
		}
		setValidated(true);
		if (form.checkValidity()) {
			console.log("true");
			console.log(costForm, "questionsForm");
			e.preventDefault();
			e.stopPropagation();
			const res = await requestCost(localStorage.getItem("token"),costForm);
			console.log(res, "res");
			if (res) {
				tabChangeForm(e, "nuances", "documents", "costs", "next")
				setValidated(false);
			} 

		}
	}

	const NuancesFormSubmit = async (e) => {
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
			e.preventDefault();
			e.stopPropagation();
			console.log("false");
		}
		setValidated(true);
		if (form.checkValidity()) {
			console.log("true");
			console.log(nuancesForm, "questionsForm");
			e.preventDefault();
			e.stopPropagation();
			const res = await requestNuances(localStorage.getItem("token"),nuancesForm);
			console.log(res, "res");
			if (res) {
				tabChangeForm(e, "postmortem", "costs", "nuances", "next");
				setValidated(false);
			} 

		}
	}

	useEffect(() => {
		getEventRegions(localStorage.getItem("token"));
	}, [getEventRegions]);

	useEffect(() => {
		if (eventRegions) {
			setEventRegionsList(eventRegions);
		}
	}, [eventRegions]);

	const RetriveEventType = (id) => {
		setEventTypesList(null);
		getEventTypes(localStorage.getItem("token"),id);
	}	

	useEffect(() => {
		if (eventTypes) {
			setEventTypesList(eventTypes);
		}
	}, [eventTypes]);

	useEffect(() => {
		for (let index = 1; index < 7; index++) {
			if(questionsForm['questionanswer'+index] === 'true' || questionsForm['questionanswer'+index] === null){
				setIsDisabled(true);
				setmanagementOverRide("");
			} else {
				setIsDisabled(false);
				index = 8;
			}	 		
		}
	},[questionsForm])

	useEffect(() => {
		console.log(distributionAccounts);
		let accounts_data = distributionAccounts;
		if (accounts_data) {
			setDistributionAccounts(accounts_data.Data);
		}
	}, [distributionAccounts]);


	useEffect(() => {
		axios.get("https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code").then(response => {
			// console.log(response.data);
			let CountryData = response.data;
			setCountry(CountryData);
		});
	}, []);

	return (
		<div className="widget">
			<div className="widget-head">Events Request Form</div>
			<div className="widget-body">
				<div className="step-arrow-nav">
					<Tabs activeKey={tabChange} className="mb-4 custom-nav" justify onSelect={handleSelect}>
						<Tab eventKey="summary" title="Summary" disabled tabClassName={activeTabs.summary ? "done" : ""}>
							<Form noValidate validated={validated} onSubmit={SummaryFormSubmit}>
								<Row>
									<Col lg={3} className="mb-3">
										<Form.Label>
											Event Region <small className="text-danger">*</small>
										</Form.Label>
										<Form.Select
											defaultValue=""
											required
											name="eventregion"
											onChange={(e) =>
												{
												setSummaryForm({
													...summaryForm,
													[e.target.name]: parseInt(e.target.value),
												})
												RetriveEventType(parseInt(e.target.value));
												}
											}
										>
											<option value="" disabled>
												Select Event Region...
											</option>
											{eventRegionsList
												? eventRegionsList.length > 0
													? eventRegionsList.map((i, v) => (
															<option key={v} value={i.RegionPermId}>
																{i.RegionDescriptionLong}
															</option>
													  ))
													: ""
												: ""}
										</Form.Select>
										<Form.Control.Feedback type="invalid">Please Select An Event Region</Form.Control.Feedback>
									</Col>
									<Col lg={3} className="mb-3">
										<Form.Label>
											Event Type <small className="text-danger">*</small>
										</Form.Label>
										<Form.Select
											defaultValue=""
											required
											name="eventtype"
											onChange={(e) =>
												setSummaryForm({
													...summaryForm,
													[e.target.name]: parseInt(e.target.value),
												})
											}
											disabled={!eventTypesList ? true : false}
										>
											<option value="">
												Select Event Type...
											</option>
											{eventTypesList
												? eventTypesList.length > 0
													? eventTypesList.map((i, v) => (
															<option key={v} value={i.EventPermId}>
																{i.EventDescriptionLong}
															</option>
													  ))
													: ""
												: ""}
										</Form.Select>
										<Form.Control.Feedback type="invalid">Please Select An Event Type</Form.Control.Feedback>
									</Col>
									<Col lg={3} className="mb-3">
										<Form.Label>
											Event Title <small className="text-danger">*</small>
										</Form.Label>
										<Form.Control
											type="text"
											required
											name="tasktitle"
											onChange={(e) =>
												setSummaryForm({
													...summaryForm,
													[e.target.name]: e.target.value,
												})
											}
										/>
										<Form.Control.Feedback type="invalid">Please Enter Event Title</Form.Control.Feedback>
									</Col>
									<Col lg={3} className="mb-3">
										<Form.Label>
											Event Description <small className="text-danger">*</small>
										</Form.Label>
										<Form.Control
											type="text"
											required
											name="taskdescription"
											onChange={(e) =>
												setSummaryForm({
													...summaryForm,
													[e.target.name]: e.target.value,
												})
											}
										/>
										<Form.Control.Feedback type="invalid">Please Enter Event Description</Form.Control.Feedback>
									</Col>
									<Col lg={3} className="mb-3">
										<Form.Label>
											Start Date <small className="text-danger">*</small>
										</Form.Label>
										<Form.Control
											type="datetime-local"
											required
											name="eventdatetimestart"
											onChange={(e) =>
												setSummaryForm({
													...summaryForm,
													[e.target.name]: e.target.value,
												})
											}
										/>
										<Form.Control.Feedback type="invalid">Please Enter Event Start Date</Form.Control.Feedback>
									</Col>
									<Col lg={3} className="mb-3">
										<Form.Label>
											End Date <small className="text-danger">*</small>
										</Form.Label>
										<Form.Control
											type="datetime-local"
											required
											name="eventdatetimeend"
											onChange={(e) =>
												setSummaryForm({
													...summaryForm,
													[e.target.name]: e.target.value,
												})
											}
										/>
										<Form.Control.Feedback type="invalid">Please Enter Event End Date</Form.Control.Feedback>
									</Col>
									<Col lg={3} className="mb-3">
										<Form.Label>
											Expected Temperature <small className="text-danger">*</small>
										</Form.Label>
										<Form.Control
											type="number"
											required
											name="eventtemperaturefahrenheit"
											onChange={(e) =>
												setSummaryForm({
													...summaryForm,
													[e.target.name]: parseInt(e.target.value),
												})
											}
										/>
										<Form.Control.Feedback type="invalid">Please Enter Expected Temperature</Form.Control.Feedback>
									</Col>
									<Col lg={3} className="mb-3">
										<Form.Label>
											Expected To Rain <small className="text-danger">*</small>
										</Form.Label>
										<Form.Select
											defaultValue=""
											required
											name="eventtemperaturerain"
											onChange={(e) =>
												setSummaryForm({
													...summaryForm,
													[e.target.name]: e.target.value,
												})
											}
										>
											<option value="" disabled>
												Select Expected To Rain...
											</option>
											<option value="true">Yes</option>
											<option value="false">No</option>
										</Form.Select>
										<Form.Control.Feedback type="invalid">Please Select Expected To Rain</Form.Control.Feedback>
									</Col>
									<Col lg={3} className="mb-3">
										<Form.Label>
											Bar On Site <small className="text-danger">*</small>
										</Form.Label>
										<Form.Select
											defaultValue=""
											required
											name="eventbaronsite"
											onChange={(e) =>
												setSummaryForm({
													...summaryForm,
													[e.target.name]: e.target.value,
												})
											}
										>
											<option value="" disabled>
												Select Bar On Site...
											</option>
											<option value="true">Yes</option>
											<option value="false">No</option>
										</Form.Select>
										<Form.Control.Feedback type="invalid">Please Select Bar On Site</Form.Control.Feedback>
									</Col>
									<Col lg={3} className="mb-3">
										<Form.Label>
											Expected Audience Count <small className="text-danger">*</small>
										</Form.Label>
										<Form.Control
											type="number"
											required
											name="eventaudiencecount"
											onChange={(e) =>
												setSummaryForm({
													...summaryForm,
													[e.target.name]: parseInt(e.target.value),
												})
											}
										/>
										<Form.Control.Feedback type="invalid">Please Enter Expected Audience Count</Form.Control.Feedback>
									</Col>
									<Col lg={12} className="mb-3">
										<Form.Label>Expected Notes</Form.Label>
										<Form.Control
											as="textarea"
											rows={4}
											name="eventnotes"
											onChange={(e) =>
												setSummaryForm({
													...summaryForm,
													[e.target.name]: e.target.value,
												})
											}
										/>
									</Col>
								</Row>
								<div className="d-flex align-items-start justify-content-end gap-3 mt-4">
									<button type="submit" className="btn btn-main next">
										Next <i className="fa-regular fa-arrow-right"></i>
									</button>
								</div>
							</Form>
						</Tab>
						<Tab eventKey="questions" title="Questions" disabled tabClassName={activeTabs.questions ? "done" : ""}>
							<Form noValidate validated={validated} onSubmit={QuestionsFormSubmit}>
								<Row>
									<Col lg={6} className="mb-3">
													<Form.Label>
														Will Justin or Audrey be at this event? 
													</Form.Label>
													<Form.Select
														defaultValue=""
														required
														name="questionanswer1"
														onChange={(e) =>
															setQuestionsForm({
																...questionsForm,
																[e.target.name]: e.target.value,
															})
														}
													>
														<option value="" disabled>
															Select Option
														</option>
														<option value="true">Yes</option>
														<option value="false">No</option>
													</Form.Select>
													<Form.Control.Feedback type="invalid">Please Select Option</Form.Control.Feedback>
									</Col>
									<Col lg={6} className="mb-3">
													<Form.Label>
														Can patrons taste the brand at this event? 
													</Form.Label>
													<Form.Select
														defaultValue=""
														required
														name="questionanswer2"
														onChange={(e) =>
															setQuestionsForm({
																...questionsForm,
																[e.target.name]: e.target.value,
															})
														}
													>
														<option value="" disabled>
															Select Option
														</option>
														<option value="true">Yes</option>
														<option value="false">No</option>
													</Form.Select>
													<Form.Control.Feedback type="invalid">Please Select Option</Form.Control.Feedback>
									</Col>
									<Col lg={6} className="mb-3">
													<Form.Label>
														Is this a market that we are in or want to be in?
													</Form.Label>
													<Form.Select
														defaultValue=""
														required
														name="questionanswer3"
														onChange={(e) =>
															setQuestionsForm({
																...questionsForm,
																[e.target.name]: e.target.value,
															})
														}
													>
														<option value="" disabled>
															Select Option
														</option>
														<option value="true">Yes</option>
														<option value="false">No</option>
													</Form.Select>
													<Form.Control.Feedback type="invalid">Please Select Option</Form.Control.Feedback>
									</Col>
									<Col lg={6} className="mb-3">
													<Form.Label>
													Are there at least 10 high value targets in this market?
													</Form.Label>
													<Form.Select
														defaultValue=""
														required
														name="questionanswer4"
														onChange={(e) =>
															setQuestionsForm({
																...questionsForm,
																[e.target.name]: e.target.value,
															})
														}
													>
														<option value="" disabled>
															Select Option
														</option>
														<option value="true">Yes</option>
														<option value="false">No</option>
													</Form.Select>
													<Form.Control.Feedback type="invalid">Please Select Option</Form.Control.Feedback>
									</Col>
									<Col lg={6} className="mb-3">
													<Form.Label>
														Do we want to support this market at this time?
													</Form.Label>
													<Form.Select
														defaultValue=""
														required
														name="questionanswer5"
														onChange={(e) =>
															setQuestionsForm({
																...questionsForm,
																[e.target.name]: e.target.value,
															})
														}
													>
														<option value="" disabled>
															Select Option
														</option>
														<option value="true">Yes</option>
														<option value="false">No</option>
													</Form.Select>
													<Form.Control.Feedback type="invalid">Please Select Option</Form.Control.Feedback>
									</Col>
									<Col lg={6} className="mb-3">
													<Form.Label>
														Can we support this market at this time?
													</Form.Label>
													<Form.Select
														defaultValue=""
														required
														name="questionanswer6"
														onChange={(e) =>
															setQuestionsForm({
																...questionsForm,
																[e.target.name]: e.target.value,
															})
														}
													>
														<option value="" disabled>
															Select Option
														</option>
														<option value="true">Yes</option>
														<option value="false">No</option>
													</Form.Select>
													<Form.Control.Feedback type="invalid">Please Select Option</Form.Control.Feedback>
									</Col>
									<Col className="mb-3" lg={{ span: 6, offset: 3 }}>
													<Form.Label>
														Management Override
													</Form.Label>
													<Form.Select
														// defaultValue=""
														value={managementOverRide}
														required
														disabled={isDisabled}
														name="managementOverride"
														onChange={(e) =>
															setmanagementOverRide(e.target.value)
														}
													>
														<option value="" disabled>
															Did Management Approve this Override?
														</option>
														<option value="true">Yes</option>
														<option value="false">No</option>
													</Form.Select>
													<Form.Control.Feedback type="invalid">Please Select Option</Form.Control.Feedback>
									</Col>
								</Row>
								<div className="d-flex align-items-start justify-content-between gap-3 mt-4">
									<button onClick={(e) => tabChangeForm(e, "account", "summary", "questions", "previous")} className="btn btn-main previous">
										<i className="fa-regular fa-arrow-left"></i> Previous
									</button>
									<button type="submit" className="btn btn-main next">
										Next <i className="fa-regular fa-arrow-right"></i>
									</button>
								</div>
							</Form>
						</Tab>
						<Tab eventKey="account" title="Account" disabled tabClassName={activeTabs.account ? "done" : ""}>
								<Row>
									<Col lg={12} className="mb-3">
										<div className="widget">
											<div className="widget-head">Accounts</div>
											<div className="widget-body">
												<DataTable
													value={distributionAccount}
													showGridlines
													paginator
													rows={10}
													rowsPerPageOptions={[5, 10, 25, 50]}
													selectionMode="single" selection={selectedAccount} onSelectionChange={(e) => setSelectedAccount(e.value)}
													//paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
												>
													<Column field="AccountName" header="Account Name"></Column>
													<Column field="AccountReference" header="Account Reference"></Column>
													<Column field="Address" header="Address"></Column>
													<Column field="Phone" header="Phone"></Column>
												</DataTable>
											</div>
										</div>
									</Col>
								</Row>
								<div className="d-flex align-items-start justify-content-between gap-3 mt-4">
									<button onClick={(e) => tabChangeForm(e, "address", "questions", "account", "previous")} className="btn btn-main previous">
										<i className="fa-regular fa-arrow-left"></i> Previous
									</button>
									<button onClick={AccountSubmit} className="btn btn-main next">
										Next <i className="fa-regular fa-arrow-right"></i>
									</button>
								</div>
						</Tab>
						<Tab eventKey="address" title="Address" disabled tabClassName={activeTabs.address ? "done" : ""}>
							<Form noValidate validated={validated} onSubmit={AddressFormSubmit}>
							<Row>
									<Col lg={12} className="mb-3">
										<Form.Label>
											Address Line One <small className="text-danger">*</small>
										</Form.Label>
										<Form.Control
											type="text"
											required
											name="addressstreet1"
											onChange={(e) =>
												setAddressForm({
													...addressForm,
													[e.target.name]: e.target.value,
												})
											}
										/>
										<Form.Control.Feedback type="invalid">Please Enter Address Line</Form.Control.Feedback>
									</Col>
									<Col lg={12} className="mb-3">
										<Form.Label>
											Address Line Two <small className="text-danger">*</small>
										</Form.Label>
										<Form.Control
											type="text"
											name="addressstreet2"
											onChange={(e) =>
												setAddressForm({
													...addressForm,
													[e.target.name]: e.target.value,
												})
											}
										/>
										<Form.Control.Feedback type="invalid">Please Enter Address Line</Form.Control.Feedback>
									</Col>
									<Col lg={12} className="mb-3">
										<Form.Label>
											Address Line Three <small className="text-danger">*</small>
										</Form.Label>
										<Form.Control
											type="text"
											name="addressstreet3"
											onChange={(e) =>
												setAddressForm({
													...addressForm,
													[e.target.name]: e.target.value,
												})
											}
										/>
										<Form.Control.Feedback type="invalid">Please Enter Address Line</Form.Control.Feedback>
									</Col>
									<Col lg={6} className="mb-3">
										<Form.Label>
											City <small className="text-danger">*</small>
										</Form.Label>
										<Form.Control
											type="text"
											required
											name="addresscity"
											onChange={(e) =>
												setAddressForm({
													...addressForm,
													[e.target.name]: e.target.value,
												})
											}
										/>
										<Form.Control.Feedback type="invalid">Please Enter City</Form.Control.Feedback>
									</Col>
									<Col lg={6} className="mb-3">
										<Form.Label>
											State / Province / Region <small className="text-danger">*</small>
										</Form.Label>
										<Form.Control
											type="text"
											required
											name="addressstateprovince"
											onChange={(e) =>
												setAddressForm({
													...addressForm,
													[e.target.name]: e.target.value,
												})
											}
										/>
										<Form.Control.Feedback type="invalid">Please Enter State / Province / Region</Form.Control.Feedback>
									</Col>
									<Col lg={6} className="mb-3">
										<Form.Label>
											ZIP / Postal Code <small className="text-danger">*</small>
										</Form.Label>
										<Form.Control
											type="text"
											required
											name="addresszippostalcode"
											onChange={(e) =>
												setAddressForm({
													...addressForm,
													[e.target.name]: e.target.value,
												})
											}
										/>
										<Form.Control.Feedback type="invalid">Please Enter ZIP / Postal Code </Form.Control.Feedback>
									</Col>
									<Col lg={6} className="mb-3">
										<Form.Label>
											Country <small className="text-danger">*</small>
										</Form.Label>
										<Form.Select
											defaultValue=""
											required
											name="addresscountryregion"
											onChange={(e) =>
												{
												setAddressForm({
													...addressForm,
													[e.target.name]: e.target.value
												})
												}
											}
										>
											<option value="" disabled>
												Select Option
											</option>
											{country
												? country.countries.length > 0
													? country.countries.map((i, v) => (
															<option key={v} value={i.value}>
																{i.label}
															</option>
													  ))
													: ""
												: ""}
										</Form.Select>
										<Form.Control.Feedback type="invalid">Please Select Country</Form.Control.Feedback>
									</Col>
								</Row>
								<div className="d-flex align-items-start justify-content-between gap-3 mt-4">
									<button onClick={(e) => tabChangeForm(e, "documents", "account", "address", "previous")} className="btn btn-main previous">
										<i className="fa-regular fa-arrow-left"></i> Previous
									</button>
									<button type="submit" className="btn btn-main next">
										Next <i className="fa-regular fa-arrow-right"></i>
									</button>
								</div>
							</Form>
						</Tab>
						<Tab eventKey="documents" title="Documents" disabled tabClassName={activeTabs.documents ? "done" : ""}>
							<Form noValidate validated={validated} onSubmit={UploadFormSubmit}> 
								<Row>
									<Col lg={4} className="mb-3">
										<Form.Label>
											Document Type
										</Form.Label>
										<Form.Select
											defaultValue=""
											name="documenttype"
											required
										>
											<option value="" disabled>
												Select Option
											</option>
											{documentTypes.map((v) => (
															<option key={v} value={v}>
																{v}
															</option>
													  ))}
										</Form.Select>
										<Form.Control.Feedback type="invalid">Please Select Type</Form.Control.Feedback>
									</Col>
									<Col lg={8} className="mb-3">
										<Form.Label>Upload Document</Form.Label>
										<Form.Control 
										type="file" 
										name="file" 
										id="file"
										accept=".pdf,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
										required 
										onChange={(e) =>
												{
												setUploadForm({
													uploadForm,
													[e.target.name]: e.target.files[0],
												})
												}
											}/>
										<Form.Control.Feedback type="invalid">Please Upload File</Form.Control.Feedback>
									</Col>
								</Row>
								<div className="d-flex align-items-start justify-content-between gap-3 mt-4">
									<button onClick={(e) => tabChangeForm(e, "costs", "address", "documents", "previous")} className="btn btn-main previous">
										<i className="fa-regular fa-arrow-left"></i> Previous
									</button>
									<button type="submit" className="btn btn-main next">
										Next <i className="fa-regular fa-arrow-right"></i>
									</button>
								</div>
							</Form>
						</Tab>
						<Tab eventKey="costs" title="Costs" disabled tabClassName={activeTabs.costs ? "done" : ""}>
							<Form noValidate validated={validated} onSubmit={CostFormSubmit}>
								<Row>
									<Col lg={6} className="mb-3">
										<Form.Label>
											Cost Type
										</Form.Label>
										<Form.Select
											defaultValue=""
											name="costtype"
											required
										>
											<option value="" disabled>
												Select Option
											</option>
											{costTypes.map((v) => (
															<option key={v} value={v}>
																{v}
															</option>
													  ))}
										</Form.Select>
										<Form.Control.Feedback type="invalid">Please Select Type</Form.Control.Feedback>
									</Col>
									<Col lg={6} className="mb-3">
										<Form.Label>
											Input Cost <small className="text-danger">*</small>
										</Form.Label>
										<Form.Control
											type="number"
											required
											name="costamount"
											onChange={(e) =>
												setCostForm({
													...costForm,
													[e.target.name]: parseInt(e.target.value),
												})
											}
										/>
										<Form.Control.Feedback type="invalid">Please Enter Amount</Form.Control.Feedback>
									</Col>
								</Row>
								<div className="d-flex align-items-start justify-content-between gap-3 mt-4">
									<button onClick={(e) => tabChangeForm(e, "nuances", "documents", "costs", "previous")} className="btn btn-main previous">
										<i className="fa-regular fa-arrow-left"></i> Previous
									</button>
									<button type="submit" className="btn btn-main next">
										Next <i className="fa-regular fa-arrow-right"></i>
									</button>
								</div>
							</Form>
						</Tab>
						<Tab eventKey="nuances" title="Nuances" disabled tabClassName={activeTabs.nuances ? "done" : ""}>
							<Form noValidate validated={validated} onSubmit={NuancesFormSubmit}>
								<Row>
									<Col lg={4} className="mb-3">
										<Form.Label>
											Nuances Type
										</Form.Label>
										<Form.Select
											defaultValue=""
											name="nuancestype"
											required
										>
											<option value="" disabled>
												Select Option
											</option>
											{nuancesTypes.map((v) => (
															<option key={v} value={v}>
																{v}
															</option>
													  ))}
										</Form.Select>
										<Form.Control.Feedback type="invalid">Please Select Type</Form.Control.Feedback>
									</Col>
									<Col lg={4} className="mb-3">
										<Form.Label>
											Max Number <small className="text-danger">*</small>
										</Form.Label>
										<Form.Control
											type="number"
											required
											name="nuancemaximum"
											onChange={(e) =>
												setNuancesForm({
													...nuancesForm,
													[e.target.name]: parseInt(e.target.value),
												})
											}
										/>
										<Form.Control.Feedback type="invalid">Please Enter Number</Form.Control.Feedback>
									</Col>
									<Col lg={4} className="mb-3">
										<Form.Label>
											Nuances Price <small className="text-danger">*</small>
										</Form.Label>
										<Form.Control
											type="number"
											required
											name="nuanceprice"
											onChange={(e) =>
												setNuancesForm({
													...nuancesForm,
													[e.target.name]: parseInt(e.target.value),
												})
											}
										/>
										<Form.Control.Feedback type="invalid">Please Enter Price</Form.Control.Feedback>
									</Col>
								</Row>
								<div className="d-flex align-items-start justify-content-between gap-3 mt-4">
									<button onClick={(e) => tabChangeForm(e, "postmortem", "costs", "nuances", "previous")} className="btn btn-main previous">
										<i className="fa-regular fa-arrow-left"></i> Previous
									</button>
									<button type="submit" className="btn btn-main next">
										Next <i className="fa-regular fa-arrow-right"></i>
									</button>
								</div>
							</Form>
						</Tab>
						<Tab eventKey="postmortem" title="Postmortem" disabled tabClassName={activeTabs.postmartem ? "done" : ""}>
							<Form>
								<Row>
									<Col lg={6} className="mb-3">
										<Form.Label>
											Actual Start Date? <small className="text-danger">*</small>
										</Form.Label>
										<Form.Control
											type="datetime-local"
											required
											name="actualstart"
											disabled
										/>
										<Form.Control.Feedback type="invalid">Please Enter Date</Form.Control.Feedback>
									</Col>
									<Col lg={6} className="mb-3">
										<Form.Label>
											Actual End Date? <small className="text-danger">*</small>
										</Form.Label>
										<Form.Control
											type="datetime-local"
											required
											name="actualend"
											disabled
										/>
										<Form.Control.Feedback type="invalid">Please Enter Date</Form.Control.Feedback>
									</Col>
									<Col lg={6} className="mb-3">
										<Form.Label>
											Actual Event Temperature? <small className="text-danger">*</small>
										</Form.Label>
										<Form.Control
											type="number"
											required
											name="temp"
											disabled
										/>
										<Form.Control.Feedback type="invalid">Please Enter Temperature</Form.Control.Feedback>
									</Col>
									<Col lg={6} className="mb-3">
										<Form.Label>
											Did It Rain? <small className="text-danger">*</small>
										</Form.Label>
										<Form.Select
											defaultValue=""
											required
											name="rain"
											disabled
										>
											<option value="" disabled>
												Select Option
											</option>
											<option value="true">Yes</option>
											<option value="false">No</option>
										</Form.Select>
										<Form.Control.Feedback type="invalid">Please Option</Form.Control.Feedback>
									</Col>
									<Col lg={6} className="mb-3">
										<Form.Label>
											Was There a Bar on Site? <small className="text-danger">*</small>
										</Form.Label>
										<Form.Select
											defaultValue=""
											required
											name="bar"
											disabled
										>
											<option value="" disabled>
												Select Option
											</option>
											<option value="true">Yes</option>
											<option value="false">No</option>
										</Form.Select>
										<Form.Control.Feedback type="invalid">Please Select Option</Form.Control.Feedback>
									</Col>
									<Col lg={6} className="mb-3">
										<Form.Label>
											Actual Audience Count <small className="text-danger">*</small>
										</Form.Label>
										<Form.Control
											type="number"
											required
											name="count"
											disabled
										/>
										<Form.Control.Feedback type="invalid">Please Enter Count</Form.Control.Feedback>
									</Col>
									<Col lg={12} className="mb-3">
										<Form.Label>
											After Event Notes <small className="text-danger">*</small>
										</Form.Label>
										<Form.Control
											type="textarea"
											required
											name="notes"
											style={{ height: '100px' }}
											disabled
										/>
										<Form.Control.Feedback type="invalid">Please Types Notes</Form.Control.Feedback>
									</Col>
									
								</Row>
								<div className="d-flex align-items-start justify-content-between gap-3 mt-4">
									<button onClick={(e) => tabChangeForm(e, null, "nuances", "postmortem", "previous")} className="btn btn-main previous">
										<i className="fa-regular fa-arrow-left"></i> Previous
									</button>
									<button onClick={(e) => tabChangeForm(e, null, "nuances", "postmortem", "next")} className="btn btn-main next">
										Next <i className="fa-regular fa-arrow-right"></i>
									</button>
								</div>
							</Form>
						</Tab>
					</Tabs>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	all: state.all,
});

export default connect(mapStateToProps, { getEventRegions, requestEvent, getEventTypes, getDistributionAccount, questionsEvent, requestOverrideEvent, FileUploadEvent, requestNuances, requestCost, generateToast })(EventsRequest);
