import {
	EVENT_ASSETS_1,
	EVENT_ASSETS_2,
	EVENT_ASSETS_3,
	FILE_UPLOAD,
	GENERATE_TOAST,
	GET_DISTRIBUTION_ACCOUNTS,
	GET_EVENT_REGIONS,
	GET_EVENT_TYPES,
	GET_NOTIFICATIONS_HISTORY,
	GET_REQUESTS,
	GET_TOPICS,
	GET_TOPIC_MESSAGES,
	QUESTIONS_REQUEST,
	REMOVE_TOAST,
	REQUESTS_ERROR,
	REQUEST_COST,
	REQUEST_NUANCES,
	REQUEST_OVERRIDE,
	POS_PRODUCTS,
	POS_APPROVAL_LIST,
} from "../Actions/types";

const intitalState = {
	requests: null,
	eventRegions: null,
	eventTypes: null,
	distributionAccounts: null,
	questionsRequest: null,
	requestOverride: null,
	fileUpload: null,
	costRequest: null,
	nuancesRequest: null,
	topics: null,
	toast: [],
	notificationsHistory: null,
	topicMessages: null,
	eventAssets: {
		asset1: null,
		asset2: null,
		asset3: null,
	},
	posProducts: null,
	posApprovals: null,
};

export default function allReducer(state = intitalState, action) {
	switch (action.type) {
		case GET_REQUESTS:
			return {
				...state,
				requests: action.payload,
			};
		case GET_EVENT_REGIONS:
			return {
				...state,
				eventRegions: action.payload.Data,
			};
		case GET_EVENT_TYPES:
			return {
				...state,
				eventTypes: action.payload.Data,
			};
		case QUESTIONS_REQUEST:
			return {
				...state,
				questionsRequest: action.payload,
			};
		case REQUEST_OVERRIDE:
			return {
				...state,
				requestOverride: action.payload,
			};
		case GET_DISTRIBUTION_ACCOUNTS:
			return {
				...state,
				distributionAccounts: action.payload,
			};
		case FILE_UPLOAD:
			return {
				...state,
				fileUpload: action.payload,
			};
		case REQUEST_COST:
			return {
				...state,
				costRequest: action.payload,
			};
		case REQUEST_NUANCES:
			return {
				...state,
				nuancesRequest: action.payload,
			};
		case GET_TOPICS:
			return {
				...state,
				topics: action.payload.Topics,
			};
		case GENERATE_TOAST:
			return {
				...state,
				toast: action.payload,
			};
		case REMOVE_TOAST:
			return {
				...state,
				toast: state.toast.filter((val, i) => i !== action.payload),
			};
		case GET_NOTIFICATIONS_HISTORY:
			return {
				...state,
				notificationsHistory: action.payload,
			};
		case GET_TOPIC_MESSAGES:
			return {
				...state,
				topicMessages: action.payload,
			};
		case EVENT_ASSETS_1:
			return {
				...state,
				eventAssets: {
					...state.eventAssets,
					asset1: action.payload,
				},
			};
		case EVENT_ASSETS_2:
			return {
				...state,
				eventAssets: {
					...state.eventAssets,
					asset2: action.payload,
				},
			};
		case EVENT_ASSETS_3:
			return {
				...state,
				eventAssets: {
					...state.eventAssets,
					asset3: action.payload,
				},
			};
		case POS_PRODUCTS:
			return {
				...state,
				posProducts: action.payload,
			};
		case POS_APPROVAL_LIST:
			return {
				...state,
				posApprovals: action.payload,
			};
		case REQUESTS_ERROR:
			return {
				...state,
				requests: null,
			};
		default:
			return state;
	}
}
